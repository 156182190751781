
import { numberPadding } from "@/lib/util";
import Vue from "vue";
import Routes from "./nav-drawer/routes.vue";
import VDialogConfirm from "./v-dialog-confirm.vue";

export default Vue.extend({
  name: "NavDrawer",

  components: { Routes, VDialogConfirm },

  data() {
    return {
      loading: false,
      dialogConfirm: false,
    };
  },

  computed: {
    visible: {
      get(): boolean {
        return this.$store.state.navDrawerVisible;
      },
      set(visible: boolean): void {
        this.$store.commit("setNavDrawer", visible);
      },
    },

    loggedIn(): boolean {
      return this.$store.state.loggedIn;
    },

    isMobile(): boolean {
      return this.$vuetify.breakpoint.mobile;
    },

    username(): string {
      return this.$store.state.user?.usuario ?? "Usuário";
    },

    section(): string {
      const id = this.$store.state.section?.id ?? 0;
      const nome = this.$store.state.section?.nome ?? "Setor";
      return `${numberPadding(id)} - ${nome}`;
    },

    region(): string {
      const id = this.$store.state.region?.id ?? 0;
      const nome = this.$store.state.region?.nome_raz ?? "Região";
      return `${numberPadding(id)} - ${nome}`;
    },
  },

  methods: {
    logout() {
      this.dialogConfirm = false;
      this.$store.commit("logout");
      this.$store.commit("setNavDrawer", false);
    },
  },
});
