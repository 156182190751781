export default class ADate {
  static yesterday(useUTC: boolean | undefined = false): ADate {
    return new ADate(undefined, useUTC).addDate(0, 0, -1);
  }

  static today(useUTC: boolean | undefined = false): ADate {
    return new ADate(undefined, useUTC);
  }

  static tomorrow(useUTC: boolean | undefined = false): ADate {
    return new ADate(undefined, useUTC).addDate(0, 0, 1);
  }

  readonly date: Date;
  readonly dateISO: string;

  // en-us
  readonly dateString: string;
  readonly timeString: string;
  readonly dateTimeString: string;

  // locale
  readonly localeDateString: string;
  readonly localeTimeString: string;
  readonly localeDateTimeString: string;

  constructor(date: Date = new Date(), useUTC: boolean | undefined = false) {
    const localeOptions = useUTC ? { timeZone: "UTC" } : undefined;

    // year-month-dateThour:minute-second.millisZ
    const rule = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}).(\d{3})Z$/;

    this.date = date;
    this.dateISO = this.date.toISOString();

    // en-us
    this.dateString = this.dateISO.replace(rule, "$1-$2-$3");
    this.timeString = this.dateISO.replace(rule, "$4:$5:$6");
    this.dateTimeString = this.dateISO.replace(rule, "$1-$2-$3 $4:$5:$6");

    // locale
    this.localeDateString = this.date.toLocaleDateString(
      undefined,
      localeOptions
    );
    this.localeTimeString = this.date.toLocaleTimeString(
      undefined,
      localeOptions
    );
    this.localeDateTimeString = this.date.toLocaleString(
      undefined,
      localeOptions
    );
  }

  static parse(str: string, useUTC: boolean | undefined = false): ADate {
    return new ADate(new Date(str), useUTC);
  }

  addDate(years: number, months: number, days: number): ADate {
    const newDate = new Date(this.date);
    newDate.setFullYear(newDate.getFullYear() + years);
    newDate.setMonth(newDate.getMonth() + months);
    newDate.setDate(newDate.getDate() + days);

    return new ADate(newDate);
  }
}
