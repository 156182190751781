
import IPermission from "@/lib/interfaces/permission";
import Vue from "vue";
import { RouteRecordPublic } from "vue-router";
import { isAllowed } from "@/lib/permissions";

export default Vue.extend({
  name: "Routes",

  data() {
    return {
      firstLevel: [
        { title: "Worganize", name: "worganize", icon: "mdi-dashboard-view" },
        { title: "Setores", name: "setor", icon: "mdi-bank" },
        { title: "Pessoa", name: "pessoa", icon: "mdi-account-box-multiple" },
        { title: "Usuários", name: "usuario", icon: "mdi-account-group" },
        {
          title: "Movimentações",
          name: "movimentacao",
          icon: "mdi-swap-horizontal-variant",
        },
        { title: "Auditoria", name: "auditoria", icon: "mdi-magnify" },
        { title: "Importação", name: "import", icon: "mdi-application-import" },
      ],
    };
  },

  computed: {
    permissions(): Array<IPermission> {
      return this.$store.state.permissions;
    },

    availableViews(): Array<RouteRecordPublic> {
      return this.$router
        .getRoutes()
        .filter(
          (view) =>
            view.meta.indexable && isAllowed(view.meta.permission, "leitura")
        )
        .sort((a, b) => (a.meta?.order ?? 0) - (b.meta?.order ?? 0));
    },

    menu(): {
      title: string;
      name: string;
      icon: string;
      children: RouteRecordPublic[];
    }[] {
      return this.firstLevel
        .map((item) => ({
          ...item,
          children: this.availableViews.filter(
            (view) => view.meta.parentName === item.name
          ),
        }))
        .filter((item) => item.children.length > 0);
    },
  },

  watch: {
    $route: {
      immediate: true,
      handler(to) {
        let title = "";
        let icon = "";

        for (const item of this.menu) {
          const child = item.children.find((child) => child.name === to.name);

          if (child) {
            title = child.meta.title;
            icon = child.meta.icon;

            break;
          }
        }

        if (!title) title = to.meta?.title ?? "";
        if (!icon) icon = to.meta?.icon ?? "";

        this.$store.commit("setPageData", { title, icon });
      },
    },
  },
});
