
import Vue from "vue";
import AppBar from "./app-bar.vue";
import login from "./login.vue";
import NavDrawer from "./nav-drawer.vue";
import PasswordRecovery from "./password-recovery.vue";
import VDialogUpdating from "./v-dialog-updating.vue";
import PasswordExpired from "./password-expired.vue";
import ACard from "../elements/a-card.vue";

export default Vue.extend({
  name: "Page",

  components: {
    login,
    NavDrawer,
    AppBar,
    PasswordRecovery,
    VDialogUpdating,
    PasswordExpired,
    ACard,
  },

  data() {
    return {
      passwordRecovery: false,
      passwordExpired: false,
      usuario: "",
    };
  },

  created() {
    document.title = `Worganize | ${this.title}`;
  },

  computed: {
    globalLoading(): boolean {
      return this.$store.state.globalLoading;
    },

    loggedIn(): boolean {
      return this.$store.state.loggedIn;
    },

    title(): string {
      return this.$store.state.pageTitle;
    },

    icon(): string {
      return this.$store.state.pageIcon;
    },

    cFluid(): boolean {
      return (
        Boolean(this.$route.meta?.fluid) &&
        !this.passwordRecovery &&
        !this.passwordExpired
      );
    },

    cContainerClass(): string {
      const classes = [
        "fill-height d-flex flex-column align-center justify-start",
      ];

      if (this.cFluid) classes.push("pa-xl-2 pa-lg-2 pa-md-1 pa-sm-1 pa-xs-0");
      else classes.push("pa-xl-16 pa-lg-16 pa-md-8 pa-sm-4 pa-xs-0");

      return classes.join(" ");
    },

    cCardClass(): string {
      const classes = ["rounded-lg"];

      if (this.cFluid) classes.push("pa-xl-0 pa-lg-0 pa-md-0 pa-sm-0 pa-xs-0");
      else classes.push("pa-xl-8 pa-lg-8 pa-md-6 pa-sm-2 pa-xs-1");

      return classes.join(" ");
    },
  },

  watch: {
    title() {
      if (this.title === "Worganize") document.title = "Worganize";
      else document.title = `Worganize | ${this.title}`;
    },
  },
});
