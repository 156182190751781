import store from "@/store";
import vuetify from "@/plugins/vuetify";
import { VuetifyThemeVariant } from "vuetify/types/services/theme";

export function setTheme(name: string): void {
  const theme = themes.find((theme) => theme.name === name) ?? themes[0];

  vuetify.framework.theme.themes.light =
    theme.lightPalette as VuetifyThemeVariant;
  vuetify.framework.theme.themes.dark =
    theme.darkPalette as VuetifyThemeVariant;

  store.commit("setTheme", name);
}

export function setDark(dark: boolean): void {
  vuetify.framework.theme.dark = dark;

  store.commit("setDark", dark);
}

export function getThemes(): Array<string> {
  return themes.map((theme) => theme.name);
}

interface ITheme {
  name: string;
  lightPalette: Partial<VuetifyThemeVariant>;
  darkPalette: Partial<VuetifyThemeVariant>;
}

const themes: Array<ITheme> = [
  {
    name: "Worganize",
    lightPalette: {
      primary: "#4499E8",
      secondary: "#BABBBD",
      accent: "#316dc8",
      error: "#E8446D",
      info: "#2196F3",
      success: "#00D092",
      warning: "#EDD65E",
      card: "#F2F4F7",
      background: "#DCE1E8",
      red: "#E8446D",
      green: "#00D092",
      blue: "#4499E8",
      yellow: "#EDD65E",
      gray: "#BABBBD",
      grey: "#BABBBD",
      navdrawer: "#E7EBF1",
      inputbg: "#FFFFFF",
      header: "#4499E8",
      close: "#FFFFFF",
      cardBtnClose: "#6d7785",
      routes: "#4499E8",
      button: "#4499E8",
    },
    darkPalette: {
      primary: "#FFFFFF",
      secondary: "#BABBBD",
      accent: "#316dc8",
      error: "#E8446D",
      info: "#2196F3",
      success: "#00D092",
      warning: "#EDD65E",
      card: "#6d7785",
      background: "#4c5563",
      red: "#E8446D",
      green: "#00D092",
      blue: "#254f8f",
      yellow: "#EDD65E",
      gray: "#FFFFFF",
      grey: "#FFFFFF",
      navdrawer: "#6d7785",
      inputbg: "#8b95a3",
      header: "#182e4f",
      close: "#6d7785",
      cardBtnClose: "#FFFFFF",
      routes: "#FFFFFF",
      button: "#254f8f",
    },
  },
];

// a: "182e4f", //navbar
// b: "6d7785", //dark list
// c: "8b95a3", //with list
// d: "254f8f", //btn blue
// e: "csd3db", //color imput
// ff: "4c5563", //background
//#1f5ab3 azul
