
import Vue from "vue";
import aCard from "../elements/a-card.vue";

export default Vue.extend({
  components: { aCard },
  name: "VDialogUpdating",

  data() {
    return {
      updating: false,
    };
  },

  created() {
    window.addEventListener("swUpdatefound", this.evOnSwUpdatefound);
    window.addEventListener("swUpdated", this.evOnSwUpdated);
    window.addEventListener("swReady", this.evOnSwReady);

    navigator.serviceWorker.addEventListener("controllerchange", () => {
      window.location.reload();
    });
  },

  methods: {
    evOnSwUpdatefound() {
      this.updating = true;
    },

    evOnSwUpdated(event: CustomEvent<ServiceWorkerRegistration>) {
      const registration = event.detail;

      if (registration?.waiting)
        registration.waiting.postMessage({ type: "SKIP_WAITING" });

      this.updating = false;
    },

    evOnSwReady() {
      this.updating = false;
    },
  },
});
