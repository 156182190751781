import ADate from "./a-date";
import { parseBRL } from "./util";

export function arrayRequired(values: string[]): string | boolean {
  return values.length < 1 ? "Este campo é obrigatório" : true;
}

export function required(value: string): string | boolean {
  return !value ? "Este campo é obrigatório" : true;
}

export function equalsLength(length: number) {
  return function (value: string): string | boolean {
    return value.length !== length
      ? `Este campo deve conter ${length} caracteres`
      : true;
  };
}

export function minLength(minLength: number) {
  return function (value: string): string | boolean {
    return value.length < minLength
      ? `Este campo deve conter pelo menos ${minLength} caracteres`
      : true;
  };
}

export function maxLength(maxLength: number) {
  return function (value: string): string | boolean {
    return value.length > maxLength
      ? `Este campo deve conter até ${maxLength} caracteres`
      : true;
  };
}

export function lengthBetween(minLength: number, maxLength: number) {
  return function (value: string): string | boolean {
    return value.length < minLength || value.length > maxLength
      ? `Este campo deve estar entre ${minLength} e ${maxLength} caracteres`
      : true;
  };
}

export function email(value: string): string | boolean {
  return !value.match(/^[0-9a-zA-Z-_.]+@[0-9a-zA-Z-.]{2,63}$/)
    ? "E-mail inválido"
    : true;
}

export function passwordMatch(
  password: string,
  required: boolean | undefined = false
) {
  return function (value: string): string | boolean {
    if (!required && !value) return true;

    return value !== password ? "As senhas não correspondem" : true;
  };
}

export function possibleValues(values: Array<string>) {
  return function (value: string): string | boolean {
    return !values.includes(value) ? "Valor inválido" : true;
  };
}

export function typeBoolean(): (value: string) => string | boolean {
  return possibleValues(["0", "1"]);
}

export function greaterThan(comparator: number) {
  return function (value: string): string | boolean {
    return parseBRL(value) <= comparator
      ? `Este campo deve ser maior que ${comparator}`
      : true;
  };
}

export function lessThan(comparator: number) {
  return function (value: string): string | boolean {
    return parseBRL(value) >= comparator
      ? `Este campo deve ser menor que ${comparator}`
      : true;
  };
}

export function before(
  comparator?: string,
  dateOnly: boolean | undefined = false
): (value: string) => string | boolean {
  if (comparator === "today") comparator = ADate.today().dateString;
  else if (comparator === "yesterday")
    comparator = ADate.yesterday().dateString;
  else if (comparator === "tomorrow") comparator = ADate.tomorrow().dateString;

  if (!comparator) comparator = new ADate().dateTimeString;
  else if (comparator.match(/^\d{4}-$\d{2}-\d{2}$/)) {
    comparator += " 00:00:00";
  } else if (comparator.match(/^\d{2}:\d{2}:\d{2}$/))
    comparator = `${new ADate().dateString} ${comparator}`;

  const deadline = new Date(comparator);
  deadline.setMilliseconds(0);
  const deadlineString = dateOnly
    ? new ADate(deadline, true).localeDateString
    : new ADate(deadline, true).localeDateTimeString;

  return function (value: string): string | boolean {
    const val = new Date(value);
    val.setMilliseconds(0);

    return val >= deadline
      ? `Este campo deve ser antes de ${deadlineString}`
      : true;
  };
}

export function after(
  comparator?: string,
  dateOnly: boolean | undefined = false
): (value: string) => string | boolean {
  if (comparator === "today") comparator = ADate.today().dateString;
  else if (comparator === "yesterday")
    comparator = ADate.yesterday().dateString;
  else if (comparator === "tomorrow") comparator = ADate.tomorrow().dateString;

  if (!comparator) comparator = new ADate().dateTimeString;
  else if (comparator.match(/^\d{4}-$\d{2}-\d{2}$/)) comparator += " 00:00:00";
  else if (comparator.match(/^\d{2}:\d{2}:\d{2}$/))
    comparator = `${new ADate().dateString} ${comparator}`;

  const deadline = new Date(comparator);
  deadline.setMilliseconds(0);
  const deadlineString = dateOnly
    ? new ADate(deadline, true).localeDateString
    : new ADate(deadline, true).localeDateTimeString;

  return function (value: string): string | boolean {
    const val = new Date(value);
    val.setMilliseconds(0);

    return val <= deadline
      ? `Este campo deve ser após de ${deadlineString}`
      : true;
  };
}

export function between(
  start: string,
  end: string,
  dateOnly: boolean | undefined = false
): (value: string) => string | boolean {
  if (start === "today") start = ADate.today().dateString;
  else if (start === "yesterday") start = ADate.yesterday().dateString;
  else if (start === "tomorrow") start = ADate.tomorrow().dateString;

  if (end === "today") end = ADate.today().dateString;
  else if (end === "yesterday") end = ADate.yesterday().dateString;
  else if (end === "tomorrow") end = ADate.tomorrow().dateString;

  if (!start) start = new ADate().dateTimeString;
  else if (start.match(/^\d{4}-$\d{2}-\d{2}$/)) start += " 00:00:00";
  else if (start.match(/^\d{2}:\d{2}:\d{2}$/))
    start = `${new ADate().dateString} ${start}`;

  const startDeadline = new Date(start);
  startDeadline.setMilliseconds(0);
  const startDeadlineString = dateOnly
    ? new ADate(startDeadline, true).localeDateString
    : new ADate(startDeadline, true).localeDateTimeString;

  if (!end) end = new ADate().dateTimeString;
  else if (end.match(/^\d{4}-$\d{2}-\d{2}$/)) end += " 00:00:00";
  else if (end.match(/^\d{2}:\d{2}:\d{2}$/))
    end = `${new ADate().dateString} ${end}`;

  const endDeadline = new Date(end);
  endDeadline.setMilliseconds(0);
  const endDeadlineString = dateOnly
    ? new ADate(endDeadline, true).localeDateString
    : new ADate(endDeadline, true).localeDateTimeString;

  return function (value: string): string | boolean {
    const val = new Date(value);
    val.setMilliseconds(0);

    return val <= startDeadline || val >= endDeadline
      ? `Este campo deve ser entre ${startDeadlineString} e ${endDeadlineString}`
      : true;
  };
}

export function numberBetween(min: number, max: number) {
  return function (value: string): string | boolean {
    const val = Number(value);
    return val < min || val > max
      ? `Este campo deve estar entre ${min} e ${max}`
      : true;
  };
}

export function cpf(value: string): string | boolean {
  const message = "CPF inválido";

  const cpf = value
    .replaceAll(/[^\d]/g, "")
    .split("")
    .reverse()
    .map((c) => Number(c));

  if (cpf.length !== 11) return message;

  const r2 = cpf.shift() as number;
  const r1 = cpf.shift() as number;

  let v1 = 0;
  let v2 = 0;
  for (let i = 0; i < cpf.length; i++) {
    v1 += cpf[i] * (9 - (i % 10));
    v2 += cpf[i] * (9 - ((i + 1) % 10));
  }

  v1 = (v1 % 11) % 10;
  v2 = v2 + v1 * 9;
  v2 = (v2 % 11) % 10;

  return r1 === v1 && r2 === v2 ? true : message;
}

export function cnpj(value: string): string | boolean {
  const message = "CNPJ inválido";

  const cnpj = value
    .replaceAll(/[^\d]/g, "")
    .split("")
    .map((c) => Number(c));

  if (cnpj.length !== 14) return message;

  let v1 = 0;
  let v2 = 0;

  v1 = 5 * cnpj[0] + 4 * cnpj[1] + 3 * cnpj[2] + 2 * cnpj[3];
  v1 += 9 * cnpj[4] + 8 * cnpj[5] + 7 * cnpj[6] + 6 * cnpj[7];
  v1 += 5 * cnpj[8] + 4 * cnpj[9] + 3 * cnpj[10] + 2 * cnpj[11];
  v1 = 11 - (v1 % 11);
  if (v1 >= 10) v1 = 0;

  v2 = 6 * cnpj[0] + 5 * cnpj[1] + 4 * cnpj[2] + 3 * cnpj[3];
  v2 += 2 * cnpj[4] + 9 * cnpj[5] + 8 * cnpj[6] + 7 * cnpj[7];
  v2 += 6 * cnpj[8] + 5 * cnpj[9] + 4 * cnpj[10] + 3 * cnpj[11];
  v2 += 2 * cnpj[12];
  v2 = 11 - (v2 % 11);
  if (v2 >= 10) v2 = 0;

  return v1 === cnpj[12] && v2 === cnpj[13] ? true : message;
}

export function cpfCnpj(value: string): string | boolean {
  value = value.replaceAll(/[^\d]/g, "");

  if (value.length === 11) return cpf(value);
  else if (value.length === 14) return cnpj(value);
  else return "CPF/CNPJ inválido";
}

export function maskedPhone(value: string): string | boolean {
  const rule = new RegExp(/^\(\d{2}\) \d{4,5}-\d{4}$/);
  return rule.test(value) ? true : "Telefone inválido";
}

export function cep(value: string): string | boolean {
  const rule = new RegExp(/^\d{5}-\d{3}$/);
  return rule.test(value) ? true : "CEP inválido";
}
