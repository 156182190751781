import Axios from "axios";
import { Rq } from "./amdt";
import store from "@/store";

const axios = Axios.create({
  baseURL: process.env.NODE_ENV === "production" ? "" : "http://localhost:7780",
});

// executado antes da requisição ser enviada
axios.interceptors.request.use(
  function (config) {
    // adiciona token da sessão se existir
    const sessionToken = store.state.sessionToken;
    if (sessionToken) config.headers["Session-Token"] = sessionToken;

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// executado após a resposta ser recebida
axios.interceptors.response.use(
  function (response) {
    // atualiza token, se necessário
    if (response.headers["Session-Token"])
      store.commit("setSessionToken", response.headers["Session-Token"]);

    // disparado quando o status da requisição está na faixa 2xx
    return response;
  },
  function (error) {
    // disparado quando o status da requisição está fora da faixa 2xx ou a requisição não obteve resposta
    if (error.response) {
      // atualiza token, se necessário
      if (error.response.headers["Session-Token"])
        store.commit(
          "setSessionToken",
          error.response.headers["Session-Token"]
        );

      // A requisição foi feita e a resposta veio com um status code
      // fora da faixa 2xx
    } else if (error.request) {
      // A requisição foi feita mas não obteve resposta
      // `error.request` é uma instância de XMLHttpRequest no navegador e uma instância de
      // http.ClientRequest no node.js
      const rq = new Rq("");
      rq.ret_id = -1;
      rq.ret_msg = "Não foi possível se comunicar com o servidor";
      error.data = rq;
    } else {
      // Something happened in setting up the request that triggered an Error
    }

    return Promise.reject(error);
  }
);

export default axios;
