
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "VAlertMessage",

  props: {
    ret_id: {
      type: Number as PropType<number>,
      required: true,
    },

    ret_msg: {
      type: String as PropType<string>,
      required: true,
    },

    dismissible: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
  },

  data() {
    return {
      visible: false,
    };
  },

  watch: {
    ret_id() {
      this.visible = Boolean(this.ret_id);
    },

    ret_msg() {
      this.visible = Boolean(this.ret_msg);
    },

    visible() {
      if (this.visible) this.focus();
    },
  },

  computed: {
    alertType(): string {
      return this.ret_id < 1 ? "error" : "success";
    },

    cAlert(): HTMLElement {
      return this.$refs.alert as HTMLElement;
    },
  },

  methods: {
    focus() {
      this.cAlert.scrollIntoView({
        block: "center",
        inline: "center",
        behavior: "smooth",
      });
    },
  },
});
