import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  // página inicial
  {
    path: "/worganize",
    name: "worganize",
    alias: "/",
    meta: {
      indexable: true,
      title: "Worganize",
      icon: "mdi-view-dashboard",
      permission: "dashboard",
      parentName: "worganize",
      hideClose: true,
      order: 0,
    },
    component: () =>
      import(/* webpackChunkName: "worganize" */ "../views/Worganize.vue"),
  },

  // dev
  {
    path: "/dev-settings",
    name: "dev-settings",
    meta: {
      indexable: true,
      title: "Desenvolvimento",
      icon: "mdi-dev-to",
      permission: "dev",
      parentName: "worganize",
      order: 1,
    },
    component: () =>
      import(/* webpackChunkName: "dev-settings" */ "../views/DevSettings.vue"),
  },

  // configurações
  {
    path: "/settings",
    name: "settings",
    alias: "",
    meta: {
      indexable: false,
      title: "Worganize",
      icon: "mdi-cog",
    },
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/Settings.vue"),
  },

  /**
   * administrativo
   */

  // usuários
  {
    path: "/users",
    name: "users",
    meta: {
      indexable: true,
      title: "Usuários",
      permission: "usuario",
      parentName: "usuario",
      icon: "mdi-account-group",
      order: 0,
    },
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Users.vue"),
  },

  // minha conta
  {
    path: "/my-account",
    name: "my-account",
    meta: {
      indexable: true,
      title: "Minha conta",
      // permission: "usuario",
      parentName: "usuario",
      icon: "mdi-account",
      order: 0,
    },
    component: () =>
      import(/* webpackChunkName: "my-account" */ "../views/MyAccount.vue"),
  },

  // perfis
  {
    path: "/profiles",
    name: "profiles",
    meta: {
      indexable: true,
      title: "Perfis",
      permission: "perfil",
      parentName: "usuario",
      icon: "mdi-card-account-details-outline",
      order: 0,
    },
    component: () =>
      import(/* webpackChunkName: "profiles" */ "../views/Profiles.vue"),
  },

  // setores
  {
    path: "/sections",
    name: "sections",
    meta: {
      indexable: true,
      title: "Setores",
      permission: "setor",
      parentName: "setor",
      icon: "mdi-bank",
      order: 0,
    },
    component: () =>
      import(/* webpackChunkName: "sections" */ "../views/Sections.vue"),
  },

  // regiões
  {
    path: "/regions",
    name: "regions",
    meta: {
      indexable: true,
      title: "Regiões",
      permission: "setor",
      parentName: "setor",
      icon: "mdi-bank",
      order: 0,
    },
    component: () =>
      import(/* webpackChunkName: "regions" */ "../views/Regions.vue"),
  },

  // // Usuário-empresa
  // {
  //   path: "/usuarios-empresas",
  //   name: "usuarios-empresas",
  //   meta: {
  //     indexable: true,
  //     title: "Usuários empresas",
  //     permission: "usuario_empresa",
  //     parentName: "usuario",
  //     icon: "mdi-account-tie",
  //     order: 0,
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "usuarios-empresas" */ "../views/UsuariosEmpresas.vue"
  //     ),
  // },

  // módulos
  // {
  //   path: "/modules",
  //   name: "modules",
  //   meta: {
  //     indexable: true,
  //     title: "Módulos",
  //     permission: "modulo",
  //     parentName: "usuario",
  //     icon: "mdi-webpack",
  //     order: 0,
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "modules" */ "../views/Modules.vue"),
  // },

  // Permissões
  {
    path: "/permissions",
    name: "pemissions",
    meta: {
      indexable: true,
      title: "Permissões",
      permission: "permissao",
      parentName: "usuario",
      icon: "mdi-check-all",
      order: 0,
    },
    component: () =>
      import(/* webpackChunkName: "permissions" */ "../views/Permissions.vue"),
  },

  // Grupo de permissões
  {
    path: "/permissions-groups",
    name: "permissions-groups",
    meta: {
      indexable: true,
      title: "Grupos de permissões",
      permission: "permissao_grupo",
      parentName: "usuario",
      icon: "mdi-check-all",
      order: 0,
    },
    component: () =>
      import(
        /* webpackChunkName: "permissions-groups" */ "../views/PermissionsGroups.vue"
      ),
  },

  // permissões do perfil
  {
    path: "/profile/:id/permissions",
    name: "profile-permissions",
    meta: {
      indexable: false,
      title: "Perfil",
      permission: "permissao",
    },
    component: () =>
      import(
        /* webpackChunkName: "profile" */ "../views/ProfilePermissions.vue"
      ),
  },

  // permissões adicionais do usuario-empresa
  {
    path: "/usuario-empresa/:id/permissions",
    name: "usuario-empresa-permissions",
    meta: {
      indexable: false,
      title: "Usuário empresa",
      permission: "permissao.usuario_empresa_permissao_extra",
    },
    component: () =>
      import(
        /* webpackChunkName: "usuario-empresa-extra-permissions" */ "../views/UsuarioEmpresaExtraPermissions.vue"
      ),
  },

  /**
   * FINANCEIRO
   */

  // tipo de conta
  {
    path: "/account-types",
    name: "account-types",
    meta: {
      indexable: true,
      title: "Tipos de conta",
      permission: "tipo_conta",
      parentName: "movimentacao",
      icon: "mdi-credit-card",
      order: 0,
    },
    component: () =>
      import(
        /* webpackChunkName: "account-types" */ "../views/AccountTypes.vue"
      ),
  },

  // agrupador do tipo de conta
  {
    path: "/account-types-bundlers",
    name: "account-types-bundlers",
    meta: {
      indexable: true,
      title: "Agrupador tipos de conta",
      permission: "tipo_conta_agrupador",
      parentName: "movimentacao",
      icon: "mdi-credit-card",
      order: 0,
    },
    component: () =>
      import(
        /* webpackChunkName: "account-types-bundler" */ "../views/AccountTypesBundlers.vue"
      ),
  },

  // transacionadores
  {
    path: "/transactioners",
    name: "transactioners",
    meta: {
      indexable: true,
      title: "Transacionadores",
      permission: "transacionadores",
      parentName: "pessoa",
      icon: "mdi-account-supervisor",
      order: 0,
    },
    component: () =>
      import(
        /* webpackChunkName: "transactioners" */ "../views/Transactioners.vue"
      ),
  },

  // pessoas
  {
    path: "/people",
    name: "people",
    meta: {
      indexable: true,
      title: "Manutenção",
      permission: "pessoas",
      parentName: "pessoa",
      icon: "mdi-account-box-multiple",
      order: 0,
    },
    component: () =>
      import(/* webpackChunkName: "people" */ "../views/People.vue"),
  },

  // pessoas contatos tipos
  {
    path: "/people-contact-types",
    name: "people-contact-types",
    meta: {
      indexable: true,
      title: "Tipos de contatos",
      permission: "pessoas_contatos_tipos",
      parentName: "pessoa",
      icon: "mdi-account-box-multiple",
      order: 0,
    },
    component: () =>
      import(
        /* webpackChunkName: "people-contact-types" */ "../views/PeopleContactTypes.vue"
      ),
  },

  // plano de contas
  {
    path: "/account_plan",
    name: "account_plan",
    meta: {
      indexable: true,
      title: "Plano de contas",
      permission: "planos_de_conta",
      parentName: "movimentacao",
      icon: "mdi-draw-pen",
      order: 0,
    },
    component: () =>
      import(
        /* webpackChunkName: "account-plans" */ "../views/AccountPlans.vue"
      ),
  },

  // pessoas endereços tipos
  {
    path: "/people-address-types",
    name: "people-address-types",
    meta: {
      indexable: true,
      title: "Tipos de endereços",
      permission: "pessoas_enderecos_tipos",
      parentName: "pessoa",
      icon: "mdi-account-box-multiple",
      order: 0,
    },
    component: () =>
      import(
        /* webpackChunkName: "people-address-types" */ "../views/PeopleAddressTypes.vue"
      ),
  },

  // Nova movimentação
  {
    path: "/movement",
    name: "movement",
    meta: {
      indexable: true,
      title: "Nova movimentação",
      permission: "nova-movimentação",
      fluid: true,
      parentName: "movimentacao",
      icon: "mdi-swap-horizontal-variant",
      order: 0,
    },
    component: () =>
      import(/* webpackChunkName: "movement" */ "../views/Movement.vue"),
  },

// Lançamentos múltiplos
{
  path: "/multiple",
  name: "multiple",
  meta: {
    indexable: true,
    title: "Lançamentos múltiplos",
    permission: "nova-movimentação", // Verifique se a permissão está correta
    fluid: true,
    parentName: "movimentacao",
    icon: "mdi-draw-pen",
    order: 0,
  },
  component: () =>
    import(/* webpackChunkName: "multiple" */ "../views/Multiple.vue"),
},

  // Movimentação rápida
  // {
  //   path: "/quick-movement",
  //   name: "quick-movement",
  //   meta: {
  //     indexable: true,
  //     title: "Movimentação rápida",
  //     permission: "nova-movimentação",
  //     fluid: true,
  //     parentName: "movimentacao",
  //     icon: "mdi-swap-horizontal-variant",
  //     order: 0,
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "quick-movement" */ "../views/MovimentacaoRapida.vue"
  //     ),
  // },

  // Histórico lançamentos
  {
    path: "/movements-history",
    name: "movements-history",
    meta: {
      indexable: true,
      title: "Histórico",
      permission: "historico_lancamento",
      parentName: "movimentacao",
      icon: "mdi-swap-horizontal-variant",
      order: 0,
    },
    component: () =>
      import(
        /* webpackChunkName: "movements-history" */ "../views/MovementsHistory.vue"
      ),
  },

  // Relatórios
  {
    path: "/reports/cost-center",
    name: "reports-cost-center",
    meta: {
      indexable: true,
      title: "Relatório por centro de custo",
      permission: "conta",
      parentName: "movimentacao",
      icon: "mdi-file-chart-outline",
      order: 0,
    },
    component: () =>
      import(/* webpackChunkName: "reports" */ "../views/Reports.vue"),
  },
  {
    path: "/reports/region",
    name: "reports-region",
    meta: {
      indexable: true,
      title: "Relatório por região",
      permission: "conta",
      parentName: "movimentacao",
      icon: "mdi-file-chart-outline",
      order: 0,
    },
    component: () =>
      import(
        /* webpackChunkName: "reports-region" */ "../views/ReportsRegion.vue"
      ),
  },

  // Centro de Custos
  {
    path: "/cost-center",
    name: "cost-center",
    meta: {
      indexable: true,
      title: "Centro de custos",
      permission: "centro_de_custo",
      parentName: "movimentacao",
      icon: "mdi-currency-usd",
      order: 0,
    },
    component: () =>
      import(/* webpackChunkName: "cost-center" */ "../views/CostCenter.vue"),
  },

  // Auditoria
  {
    path: "/audit",
    name: "audit",
    meta: {
      indexable: true,
      title: "Auditoria",
      permission: "audit",
      parentName: "auditoria",
      icon: "mdi-magnify",
      order: 0,
    },
    component: () => import(/* webpackChunkName: "audit" */ "../views/Log.vue"),
  },

  /** Importação p/ financeiro */

  // aplicações
  {
    path: "/import/apps",
    name: "import-apps",
    meta: {
      indexable: true,
      title: "Aplicações",
      permission: "import_apps",
      parentName: "import",
      icon: "mdi-application",
      order: 0,
    },
    component: () =>
      import(/* webpackChunkName: "import-apps" */ "../views/ImportApps.vue"),
  },

  // vínculo plano de conta e origem
  {
    path: "/import/account-plans-origins",
    name: "import-account-plans-origins",
    meta: {
      indexable: true,
      title: "Plano de contas origem",
      permission: "import_account_plans_origins",
      parentName: "import",
      icon: "mdi-directions-fork",
      order: 0,
    },
    component: () =>
      import(
        /* webpackChunkName: "import-account-plans-origins" */ "../views/ImportAccountPlansOrigins.vue"
      ),
  },

  // vínculo plano de conta e origem
  {
    path: "/import/app-importers-origins",
    name: "import-app-importers-origins",
    meta: {
      indexable: true,
      title: "Origens de importação",
      permission: "aplicacao_importador_origem",
      parentName: "import",
      icon: "mdi-upload-multiple",
      order: 0,
    },
    component: () =>
      import(
        /* webpackChunkName: "import-app-importers-origins" */ "../views/AppImportersOrigins.vue"
      ),
  },

  // vínculo plano de conta e origem
  {
    path: "/import/app-account-plans-importers-origins",
    name: "import-app-account-plans-importers-origins",
    meta: {
      indexable: true,
      title: "App plano conta importador origem",
      permission: "aplicacao_plano_conta_importador_origem",
      parentName: "import",
      icon: "mdi-download-multiple",
      order: 0,
    },
    component: () =>
      import(
        /* webpackChunkName: "import-account-plans-origins" */ "../views/AppAccountPlansImportersOrigins.vue"
      ),
  },

  // contas-importe
  {
    path: "/contas-importe",
    name: "contas-importe",
    meta: {
      indexable: true,
      title: "Contas importe",
      permission: "conta_importe",
      parentName: "import",
      icon: "mdi-download-multiple",
      order: 0,
    },
    component: () =>
      import(
        /* webpackChunkName: "contas-importe" */ "../views/ContasImporte.vue"
      ),
  },

  // tipos-importe
  {
    path: "/tipos-importe",
    name: "tipos-importe",
    meta: {
      indexable: true,
      title: "Tipos importe",
      permission: "tipo_importe",
      parentName: "import",
      icon: "mdi-download-multiple",
      order: 0,
    },
    component: () =>
      import(
        /* webpackChunkName: "tipos-importe" */ "../views/TiposImporte.vue"
      ),
  },

  // agrupador-tipo-conta-importe
  {
    path: "/agrupadores-tipo-conta-importe",
    name: "agrupadores-tipo-conta-importe",
    meta: {
      indexable: true,
      title: "Agrupadores",
      permission: "agrupador_tipo_conta_importe",
      parentName: "import",
      icon: "mdi-download-multiple",
      order: 0,
    },
    component: () =>
      import(
        /* webpackChunkName: "agrupadores-tipo-conta-importe" */ "../views/AgrupadoresTipoContaImporte.vue"
      ),
  },

  // agrupador-tipo-fechamento
  {
    path: "/agrupador-tipo-fechamento",
    name: "agrupador-tipo-fechamento",
    meta: {
      indexable: true,
      title: "Agrupador tipo fechamento",
      permission: "api_importer",
      parentName: "import",
      icon: "mdi-download-multiple",
      order: 0,
    },
    component: () =>
      import(
        /* webpackChunkName: "agrupadores-tipo-fechamento" */ "../views/AgrupadorTipoFechamento.vue"
      ),
  },

  // tipo-fechamento
  {
    path: "/tipos-fechamento",
    name: "tipos-fechamento",
    meta: {
      indexable: true,
      title: "Tipos de fechamento",
      permission: "api_importer",
      parentName: "import",
      icon: "mdi-download-multiple",
      order: 0,
    },
    component: () =>
      import(
        /* webpackChunkName: "tipos-fechamento" */ "../views/TiposFechamento.vue"
      ),
  },

  /** 404 NÃO DECLARAR ROTAS DEPOIS DESSE CATCH-ALL */
  {
    path: "*",
    meta: {
      title: "Worganize",
    },
    component: () =>
      import(
        /* webpackChunkName: "page-not-found" */ "../views/PageNotFound.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
