
import Vue, { PropType } from "vue";
import aBtn from "../elements/a-btn.vue";

export default Vue.extend({
  name: "VDialogConfirm",

  components: { aBtn },

  props: {
    type: {
      type: String as PropType<string>,
      required: true,
      validator(value: string) {
        return ["success", "info", "warning", "error"].includes(value);
      },
    },

    title: {
      type: String as PropType<string>,
      default: "CONFIRMAÇÃO",
    },

    text: {
      type: String as PropType<string>,
      default: "Tem certeza que deseja continuar?",
    },

    confirmIcon: {
      type: String as PropType<string>,
      default: "mdi-check-circle-outline",
    },

    confirmText: {
      type: String as PropType<string>,
      default: "Confirmar",
    },

    cancelIcon: {
      type: String as PropType<string>,
      default: "mdi-cancel",
    },

    cancelText: {
      type: String as PropType<string>,
      default: "Cancelar",
    },

    visible: {
      type: Boolean as PropType<boolean>,
      required: true,
    },

    loading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  computed: {
    cWidth(): string {
      return this.isMobile ? "100%" : "35%";
    },

    isMobile(): boolean {
      return this.$vuetify.breakpoint.mobile;
    },
  },
});
