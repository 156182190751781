
import VTextField from "@/lib/types/v-text-field";
import Vue, { PropType } from "vue";
import aTextField from "./a-text-field.vue";

export default Vue.extend({
  name: "ATextFieldPassword",

  components: { aTextField },

  props: {
    autofocus: {
      type: [Boolean, undefined] as PropType<boolean | undefined>,
      default: undefined,
    },

    backgroundColor: {
      type: String as PropType<string>,
      default: "inputbg",
    },

    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    label: {
      type: String as PropType<string | undefined>,
      default: undefined,
    },

    loading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    rules: {
      type: Array as PropType<Array<boolean | string>>,
      default: () => [],
    },

    value: {
      type: String as PropType<string>,
      default: "",
    },
  },

  data() {
    return {
      visible: false,
    };
  },

  computed: {
    _aTextField(): VTextField {
      return this.$refs["a-text-field"] as VTextField;
    },

    cAppendIcon(): string {
      return this.visible ? "mdi-eye-outline" : "mdi-eye-off-outline";
    },

    cType(): string {
      return this.visible ? "text" : "password";
    },
  },

  methods: {
    focus() {
      this._aTextField.focus();
    },
  },
});
