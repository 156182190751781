export class Rq {
  proc_run: string;
  params: Record<string, string>;
  ret_id: number;
  ret_msg: string;
  tables: Table[];

  constructor(
    proc_run: string,
    params: Record<string, string> | undefined = undefined
  ) {
    this.proc_run = proc_run;
    this.params = params ?? {};
    this.ret_id = 0;
    this.ret_msg = "";
    this.tables = [];
  }

  setParam(key: string, value: string): void {
    this.params[key] = value;
  }

  setParams(params: Record<string, string>): void {
    this.params = { ...this.params, ...params };
  }

  addTable(table: Table): Table {
    this.tables.push(table);
    return table;
  }

  addTables(...tables: Table[]): void {
    this.tables.push(...tables);
  }

  getTable(nome: string): Table | undefined {
    return this.tables.find((table) => table.nome === nome);
  }
}

export class Table {
  nome: string;
  params: Record<string, string>;
  cols: string[];
  rows: Row[];

  constructor(
    nome: string,
    params: Record<string, string> | undefined = undefined
  ) {
    this.nome = nome;
    this.params = params ?? {};
    this.cols = [];
    this.rows = [];
  }

  //#region colunas
  getColumnIndex(nome: string): number {
    return this.cols.findIndex((col) => col === nome);
  }

  addCol(name: string): number {
    return this.cols.push(name) - 1;
  }

  addCols(...name: string[]): void {
    this.cols.push(...name);
  }

  setColsFromObject(obj: Record<string, string>): void {
    this.cols = Object.keys(obj);
  }
  //#endregion

  //#region rows
  getRowObject(idxRow: number): Record<string, string> {
    const row = this.rows[idxRow].r;

    const record: Record<string, string> = {};
    this.cols.forEach((col, idx) => (record[col] = row[idx]));

    return record;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getRowsObject(): Array<any> {
    return this.rows.map((_, idx) => this.getRowObject(idx));
  }

  addRows(...rows: Row[]): void {
    this.rows.push(...rows);
  }

  addRowsFromObject(...objs: Array<Record<string, string>>): void {
    const rows = new Array<Row>();

    for (const obj of objs) {
      const r = this.cols.map((col) => String(obj[col]));
      rows.push(new Row(null, ...r));
    }

    this.addRows(...rows);
  }

  //#endregion
}

export class Row {
  p: Record<string, string>;
  r: string[];

  constructor(p: Record<string, string> | null, ...r: string[]) {
    this.p = p ?? {};
    this.r = r ?? [];
  }
}

export class Amdt {
  static parseRq(data: Record<string, never>): Rq {
    const rq = new Rq("");

    rq.proc_run = data["proc_run"];
    rq.ret_id = Number(data["ret_id"]);
    rq.ret_msg = data["ret_msg"];
    rq.params = data["params"];

    const tables: Record<string, never>[] = data["tables"];
    rq.tables = tables?.map((table) => Amdt.parseTable(table)) ?? [];

    return rq;
  }

  static parseTable(data: Record<string, never>): Table {
    const tbl = new Table("");

    tbl.nome = data["nome"];
    tbl.params = data["params"] ?? {};
    tbl.cols = data["cols"] ?? [];
    tbl.rows = data["rows"] ?? [];

    return tbl;
  }

  static parseRow(data: Record<string, never>): Row {
    const row = new Row(null);

    row.p = data["p"] ?? {};
    row.r = data["r"] ?? [];

    return row;
  }
}
