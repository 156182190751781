
import AAutocomplete from "@/components/elements/a-autocomplete.vue";
import ABtnDialogConfirm from "@/components/elements/a-btn-dialog-confirm.vue";
import ABtn from "@/components/elements/a-btn.vue";
import ACard from "@/components/elements/a-card.vue";
import ASelect from "@/components/elements/a-select.vue";
import ASwitch from "@/components/elements/a-switch.vue";
import { Rq } from "@/lib/amdt";
import Api from "@/lib/api";
import { getThemes, setTheme, setDark } from "@/lib/theming";
import { numberPadding } from "@/lib/util";
import Vue from "vue";

export default Vue.extend({
  name: "MoreMenu",

  components: {
    ABtn,
    ACard,
    ASelect,
    ASwitch,
    ABtnDialogConfirm,
    AAutocomplete,
  },

  data() {
    return {
      loading: false,
      menu: false,

      selectSetor: "",
    };
  },

  computed: {
    username(): string {
      return this.$store.state.user?.usuario ?? "Usuário";
    },

    section(): string {
      const id = this.$store.state.section?.id ?? 0;
      const nome = this.$store.state.section?.nome ?? "Setor";
      return `${numberPadding(id)} - ${nome}`;
    },

    regions(): {
      id_usuario_empresa: string;
      empresa_id: string;
      regiao_nome: string;
      nome_setor: string;
      id_setor: string;
      text_regiao: string;
      text_setor: string;
    }[] {
      const regions = this.$store.state.regions as {
        id_usuario_empresa: string;
        empresa_id: string;
        regiao_nome: string;
        nome_setor: string;
        id_setor: string;
      }[];

      return regions.map((item) => ({
        ...item,
        text_regiao: `${numberPadding(item.empresa_id)} - ${item.regiao_nome}`,
        text_setor: `${numberPadding(item.id_setor)} - ${item.nome_setor}`,
      }));
    },

    cRegions(): {
      id_usuario_empresa: string;
      empresa_id: string;
      regiao_nome: string;
      nome_setor: string;
      id_setor: string;
      text_regiao: string;
      text_setor: string;
    }[] {
      return this.regions.filter((r) => r.id_setor == this.selectSetor);
    },

    region(): string {
      const id = this.$store.state.region?.id ?? 0;
      const nome = this.$store.state.region?.nome_raz ?? "Região";
      return `${numberPadding(id)} - ${nome}`;
    },

    currentRegionId(): string {
      return this.$store.state.region?.id_usuario_empresa ?? "";
    },

    themes(): Array<string> {
      return getThemes();
    },

    theme(): string {
      return this.$store.state.theme;
    },

    dark(): string {
      return this.$store.state.dark;
    },

    globalLoading: {
      get(): boolean {
        return this.$store.state.globalLoading;
      },
      set(value: boolean) {
        this.$store.commit("globalLoading", value);
      },
    },

    setores(): { id: string; text_setor: string }[] {
      return this.regions.map((r) => ({
        id: r.id_setor,
        text_setor: r.text_setor,
      }));
    },
  },

  watch: {
    menu() {
      this.selectSetor =
        this.regions.find((r) => r.id_usuario_empresa == this.currentRegionId)
          ?.id_setor ?? "";
    },
  },

  methods: {
    setTheme,
    setDark,

    async setRegion(id_usuario_empresa: string) {
      try {
        this.globalLoading = true;

        const rq = new Rq("regiao.set", {
          id_usuario_empresa,
        });
        const rspLogin = await Api.request(rq);

        if (rspLogin.ret_id < 1) {
          console.error(rspLogin.ret_msg);
          return;
        }

        const sessionToken = rspLogin.params["session_token"];

        const tblUsuario = rspLogin.getTable("usuario");
        const user = tblUsuario?.getRowObject(0);

        const tblPermissao = rspLogin.getTable("permissao");
        const permissions = tblPermissao?.getRowsObject() ?? [];

        const tblModulo = rspLogin.getTable("modulo");
        const modules = tblModulo?.getRowsObject() ?? [];

        const tblSetor = rspLogin.getTable("setor");
        const section = tblSetor?.getRowObject(0);

        const tblRegioes = rspLogin.getTable("regioes");
        const regions = tblRegioes?.getRowsObject() ?? [];

        const tblRegiao = rspLogin.getTable("regiao");
        const region = tblRegiao?.getRowObject(0);

        this.$store.commit("logout");

        this.$store.commit("login", {
          sessionToken,
          user,
          modules,
          permissions,
          section,
          regions,
          region,
        });

        if (this.$route.name === "worganize") this.$router.go(0);
        else this.$router.push({ name: "worganize" });
      } catch (error) {
        console.error(error);
      } finally {
        this.globalLoading = false;
      }
    },
  },
});
