
import { Row, Rq, Table } from "@/lib/amdt";
import Api from "@/lib/api";
import { required, maxLength } from "@/lib/validations";
import WorgError from "@/lib/worg-error";
import Vue, { PropType } from "vue";
import ABtn from "../elements/a-btn.vue";
import ACard from "../elements/a-card.vue";
import ATextFieldPassword from "../elements/a-text-field-password.vue";
import ATextField from "../elements/a-text-field.vue";
import vAlertMessage from "./v-alert-message.vue";

export default Vue.extend({
  name: "Login",

  components: { vAlertMessage, ABtn, ATextField, ACard, ATextFieldPassword },

  props: {
    passwordRecovery: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    usuario: {
      type: String as PropType<string>,
      required: true,
    },
    passwordExpired: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },

  data() {
    return {
      ret_id: 1,
      ret_msg: "",
      loading: false,
      valid: false,

      username: "",
      password: "",
    };
  },

  methods: {
    required,
    maxLength,

    async login() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rqLogin = new Rq("user.login");

        const tblLogin = rqLogin.addTable(new Table("user"));
        tblLogin.addCol("username");
        tblLogin.addCol("password");

        tblLogin.addRows(new Row(null, this.username, this.password));

        const rspLogin = await Api.request(rqLogin);

        if (rspLogin.ret_id < 1) {
          this.ret_id = rspLogin.ret_id;
          this.ret_msg = rspLogin.ret_msg;

          const errorName = WorgError.getErrorByCode(rspLogin.ret_id).name;
          if (errorName === "AuthPasswordExpired") {
            this.$emit("update:usuario", this.username);
            this.$emit("update:password-expired", true);
          }

          return;
        }

        const sessionToken = rspLogin.params["session_token"];

        const tblUsuario = rspLogin.getTable("usuario");
        const user = tblUsuario?.getRowObject(0);

        const tblPermissao = rspLogin.getTable("permissao");
        const permissions = tblPermissao?.getRowsObject() ?? [];

        const tblModulo = rspLogin.getTable("modulo");
        const modules = tblModulo?.getRowsObject() ?? [];

        const tblSetor = rspLogin.getTable("setor");
        const section = tblSetor?.getRowObject(0);

        const tblRegioes = rspLogin.getTable("regioes");
        const regions = tblRegioes?.getRowsObject() ?? [];

        const tblRegiao = rspLogin.getTable("regiao");
        const region = tblRegiao?.getRowObject(0);

        this.$store.commit("login", {
          sessionToken,
          user,
          modules,
          permissions,
          section,
          regions,
          region,
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
