import store from "@/store";

type AccessType = "leitura" | "edicao" | "escrita" | "remocao";

export function isAllowed(permission: string, accessType: AccessType): boolean {
  if (!permission) return true;

  const perm = store.state.permissions.find(
    (perm) => perm.identificador === permission
  );

  if (!perm) return false;

  return perm[accessType] === "1";
}
