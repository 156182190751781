interface IWorgError {
  name: string;
  code: number;
  message: string;
}

export default class WorgError {
  static errors: Array<IWorgError> = [
    { name: "Unknown", code: 0, message: "Erro desconhecido" },

    // auth
    {
      name: "AuthNotSignedIn",
      code: -10,
      message: "Faça login para continuar",
    },
    {
      name: "AuthWrongCredentials",
      code: -11,
      message: "Usuário e/ou senha incorretos",
    },
    { name: "AuthSessionExpired", code: -12, message: "Sessão expirada" },
    {
      name: "AuthSessionSaveError",
      code: -13,
      message: "Não foi possível guardar a sessão",
    },
    { name: "AuthNotAuthorized", code: -14, message: "Não autorizado" },
    {
      name: "AuthUsuarioEmpresaDeactivated",
      code: -15,
      message: "Usuário-empresa desativado",
    },
    { name: "AuthUserDeactivated", code: -16, message: "Usuário desativado" },
    { name: "AuthProfileDeactivated", code: -17, message: "Perfil desativado" },
    { name: "AuthSectionDeactivated", code: -18, message: "Setor desativado" },
    {
      name: "AuthCompanyDeactivated",
      code: -19,
      message: "Empresa desativada",
    },
    {
      name: "AuthNoCompaniesLinked",
      code: -190,
      message: "Nenhuma empresa vinculada com o usuário",
    },
    {
      name: "AuthTokenExpired",
      code: -191,
      message: "Código expirado",
    },
    { name: "AuthInvalidSection", code: -192, message: "Setor inválido" },
    { name: "AuthPasswordExpired", code: -193, message: "Senha expirada" },

    // banco
    {
      name: "ErrUnkwnown",
      code: -901,
      message: "Erro Nº. -901, contate o suporte",
    },
    {
      name: "ErrConnectError",
      code: -902,
      message: "Erro Nº. -902, contate o suporte",
    },
    {
      name: "ErrQueryError",
      code: -903,
      message: "Erro Nº -903, contate o suporte",
    },
    {
      name: "ErrConstraintError",
      code: -904,
      message: "Um ou mais campos já existem no sistema",
    },
    { name: "ErrNoRows", code: -905, message: "Nenhum cadastro encontrado" },
    {
      name: "ErrDupEntry",
      code: -906,
      message: "Já existe um registro com esse valor",
    },

    // conversões

    // validações
    {
      name: "VldError",
      code: -40,
      message: "Verifique os campos e tente novamente",
    },

    // parâmetros
  ];

  // helpers
  static getErrorByName(name: string): IWorgError {
    const error = WorgError.errors.find((e) => e.name === name);

    return error ?? WorgError.errors[0];
  }

  static getErrorByCode(code: number): IWorgError {
    const error = WorgError.errors.find((e) => e.code === code);

    return error ?? WorgError.errors[0];
  }
}
