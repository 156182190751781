import store from "@/store";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { Amdt, Rq } from "./amdt";
import axios from "./worganize-axios";
import WorgError from "./worg-error";
import worgErrorObject from "./worg-error-object";

export default class Api {
  static async getBlobURL(
    endpoint: string,
    config?: AxiosRequestConfig
  ): Promise<string> {
    if (!config)
      config = {
        responseType: "arraybuffer",
        headers: {},
      };

    let response: AxiosResponse;
    try {
      response = await axios.get(endpoint, config);

      // atualiza token, se necessário
      if (response.headers["Session-Token"])
        store.commit("setSessionToken", response.headers["Session-Token"]);

      const contentType: string = response.headers["content-type"];

      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: contentType })
      );

      return url;
    } catch (error) {
      console.error(error);
      return "";
    }
  }

  static openWindow(endpoint: string): void {
    const baseURL =
      process.env.NODE_ENV === "production"
        ? window.location.origin
        : "http://localhost:7780";

    const url = new URL(endpoint, baseURL);

    const sessionToken = store.state.sessionToken;
    if (sessionToken) url.searchParams.set("session_token", sessionToken);

    window.open(
      url.toString(),
      "_blank",
      "popup=1 width=1024 height=768 top=0 left=0 noopener=1 noreferrer=1"
    );
  }

  static async request(rq: Rq, config?: AxiosRequestConfig): Promise<Rq> {
    // adiciona token da sessão se existir
    const sessionToken = store.state.sessionToken;
    if (sessionToken) rq.setParam("session_token", sessionToken);

    let response: AxiosResponse;
    try {
      response = await axios.post("/worganize/api", rq, config);

      const contentType: string = response.headers["content-type"];
      if (contentType.includes("application/pdf")) {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: contentType })
        );

        const rspRq = new Rq(rq.proc_run, { url });
        rspRq.ret_id = 1;
        rspRq.ret_msg = "Documento pronto";
        return rspRq;
      }

      const rspRq = Amdt.parseRq(response.data);

      if (rspRq.ret_id === undefined || rspRq.ret_id === null)
        rspRq.ret_id = Number(response.headers["ret_id"]);
      const worgError = WorgError.getErrorByCode(rspRq.ret_id);
      if (!rspRq.ret_msg) rspRq.ret_msg = worgError.message;

      if (rspRq.ret_id === worgErrorObject.AuthNotSignedIn.code)
        store.commit("logout");
      else if (rspRq.ret_id === worgErrorObject.AuthSessionExpired.code)
        store.commit("logout");

      return rspRq;
    } catch (error) {
      console.error(error);

      const rq = new Rq("");
      const err = worgErrorObject.Unknown;
      rq.ret_id = err.code;
      rq.ret_msg = err.message;
      return rq;
    }
  }

  // wrapper around axios.get
  static async get(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return axios.get(url, config);
  }

  // wrapper around axios.post
  static async post(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return axios.post(url, data, config);
  }

  // wrapper around axios.put
  static async put(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return axios.put(url, data, config);
  }

  // wrapper around axios.patch
  static async patch(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return axios.patch(url, data, config);
  }
}
