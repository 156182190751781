
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "AAutocomplete",

  props: {
    autofocus: {
      type: Boolean as PropType<boolean>,
      default: undefined,
    },

    backgroundColor: {
      type: String as PropType<string>,
      default: "inputbg",
    },

    clearable: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    hint: {
      type: String as PropType<string>,
      default: undefined,
    },

    items: {
      type: Array as PropType<Array<Record<string, string>>>,
      required: true,
    },

    itemText: {
      type: String as PropType<string>,
      default: "",
    },

    itemValue: {
      type: String as PropType<string>,
      default: "",
    },

    label: {
      type: String as PropType<string | undefined>,
      default: undefined,
    },

    loading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    multiple: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    noFilter: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    outlined: {
      type: Boolean as PropType<boolean>,
      default: true,
    },

    persistentHint: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    persistentPlaceholder: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    placeholder: {
      type: String as PropType<string>,
      default: "Digite para pesquisar",
    },

    readonly: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    rules: {
      type: Array as PropType<Array<boolean | string>>,
      default: () => [],
    },

    value: {
      type: [String, Array] as PropType<string | string[]>,
      default: "",
    },
  },

  computed: {
    cItemText(): string {
      if (this.itemText || this.items.length === 0) return this.itemText;

      const item = this.items[0] as Record<string, unknown>;
      const keys = Object.keys(item);

      const options = [
        "text",
        "nome",
        "descricao",
        "name",
        "description",
        "id",
      ];
      return options.find((op) => keys.includes(op)) ?? keys[0];
    },

    cItemValue(): string {
      if (this.itemValue || this.items.length === 0) return this.itemValue;

      const item = this.items[0] as Record<string, unknown>;
      const keys = Object.keys(item);

      const options = ["id", "value"];
      return options.find((op) => keys.includes(op)) ?? keys[0];
    },

    vautocomplete(): Vue & { focus(): void } {
      return this.$refs.vautocomplete as Vue & { focus(): void };
    },
  },

  methods: {
    focus() {
      this.vautocomplete.focus();
    },
  },
});
