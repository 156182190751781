
import Vue from "vue";
import page from "./components/layout/page.vue";
import { setTheme, setDark } from "./lib/theming";

export default Vue.extend({
  name: "App",

  components: { page },

  created() {
    setTheme(this.$store.state.theme);
    setDark(this.$store.state.dark);
  },
});
