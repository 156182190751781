
import Vue, { PropType } from "vue";
import VDialogConfirm from "../layout/v-dialog-confirm.vue";
import aBtn from "./a-btn.vue";

export default Vue.extend({
  name: "",

  components: { aBtn, VDialogConfirm },

  props: {
    type: {
      type: String as PropType<string>,
      default: "warning",
      validator(value: string) {
        return ["success", "info", "warning", "error"].includes(value);
      },
    },

    title: {
      type: String as PropType<string>,
      default: "CONFIRMAÇÃO",
    },

    message: {
      type: String as PropType<string>,
      default: "Tem certeza que deseja continuar?",
    },

    cancelIcon: {
      type: String as PropType<string>,
      default: "mdi-cancel",
    },

    cancelText: {
      type: String as PropType<string>,
      default: "Cancelar",
    },

    loading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    color: {
      type: String as PropType<string>,
      default: "primary",
    },

    depressed: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    icon: {
      type: String as PropType<string>,
      required: true,
    },

    outlined: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    small: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    text: {
      type: String as PropType<string>,
    },
  },

  data() {
    return {
      visible: false,
    };
  },
});
