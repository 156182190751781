import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import { en, pt } from "vuetify/src/locale";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    dark: false,
    themes: {
      light: {
        primary: "#4499E8",
        secondary: "#BABBBD",
        accent: "#316dc8",
        error: "#E8446D",
        info: "#2196F3",
        success: "#00D092",
        warning: "#EDD65E",
        card: "#F2F4F7",
        background: "#DCE1E8",
        red: "#E8446D",
        green: "#00D092",
        blue: "#4499E8",
        yellow: "#EDD65E",
        gray: "#BABBBD",
        grey: "#BABBBD",
        navdrawer: "#E7EBF1",
        inputbg: "#FFFFFF",
        header: "#4499E8",
        close: "#FFFFFF",
        cardBtnClose: "#6d7785",
        routes: "#4499E8",
        button: "#4499E8",
      },
      dark: {
        primary: "#FFFFFF",
        secondary: "#BABBBD",
        accent: "#316dc8",
        error: "#E8446D",
        info: "#2196F3",
        success: "#00D092",
        warning: "#EDD65E",
        card: "#6d7785",
        background: "#4c5563",
        red: "#E8446D",
        green: "#00D092",
        blue: "#254f8f",
        yellow: "#EDD65E",
        gray: "#FFFFFF",
        grey: "#FFFFFF",
        navdrawer: "#6d7785",
        inputbg: "#8b95a3",
        header: "#182e4f",
        close: "#6d7785",
        cardBtnClose: "#FFFFFF",
        routes: "#FFFFFF",
        button: "#254f8f",
      },
    },
  },
  lang: {
    locales: { en, pt },
    current: "pt",
  },
});

/**
 * cores logo
 * #00DC8F
 * #035563
 * #316DC8
 * #22467D
 * #F2F4F7
 * #DCE1E8
 */
