
import { Rq } from "@/lib/amdt";
import Api from "@/lib/api";
import VForm from "@/lib/types/v-form";
import {
  maxLength,
  minLength,
  passwordMatch,
  required,
} from "@/lib/validations";
import Vue, { PropType } from "vue";
import ABtn from "../elements/a-btn.vue";
import ACard from "../elements/a-card.vue";
import ATextFieldPassword from "../elements/a-text-field-password.vue";
import ATextField from "../elements/a-text-field.vue";
import vAlertMessage from "./v-alert-message.vue";

export default Vue.extend({
  name: "PasswordExpired",

  components: {
    vAlertMessage,
    ATextField,
    ABtn,
    ACard,
    ATextFieldPassword,
  },

  props: {
    passwordExpired: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    usuario: {
      type: String as PropType<string>,
      required: true,
    },
  },

  data() {
    return {
      valid: false,
      loading: false,
      ret_id: 1,
      ret_msg: "",

      success: false,

      oldPassword: "",
      newPassword: "",
      newPasswordConfirmation: "",
    };
  },

  computed: {
    form(): VForm {
      return this.$refs.form as VForm;
    },

    oldPasswordInput(): Vue & { focus(): void } {
      return this.$refs.oldPassword as Vue & { focus(): void };
    },

    cPasswordRules(): ((value: string) => string | boolean)[] {
      return [
        required,
        minLength(8),
        maxLength(40),
        (value: string) =>
          value === this.oldPassword
            ? "A nova senha não pode ser igual a anterior"
            : true,
      ];
    },

    cPasswordConfirmationRules(): ((value: string) => string | boolean)[] {
      return this.newPassword
        ? [required, passwordMatch(this.newPassword, true)]
        : [passwordMatch(this.newPassword)];
    },
  },

  mounted() {
    this.oldPasswordInput.focus();
  },

  methods: {
    required,
    minLength,
    maxLength,
    passwordMatch,

    async evOnSubmit() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("user.update-password", {
          usuario: this.usuario,
          senha_antiga: this.oldPassword,
          senha_nova: this.newPassword,
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          this.oldPasswordInput.focus();

          return;
        }

        this.ret_msg = "Senha atualizada com sucesso";
        this.oldPassword = "";
        this.newPassword = "";
        this.newPasswordConfirmation = "";
        this.success = true;

        this.form.resetValidation();
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
