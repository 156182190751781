import IModule from "@/lib/interfaces/module";
import IPermission from "@/lib/interfaces/permission";
import IPerson from "@/lib/interfaces/person";
import ISection from "@/lib/interfaces/section";
import IUser from "@/lib/interfaces/user";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

interface IRegion {
  id_usuario_empresa: string;
  empresa_id: string;
  regiao_nome: string;
  nome_setor: string;
  id_setor: string;
}
interface ILogin {
  sessionToken: string;
  user: IUser;
  modules: IModule[];
  permissions: IPermission[];
  regions: IRegion[];
  region: IPerson;
  section: ISection;
}

import VuexPersistence from "vuex-persist";
const vuexPersistence = new VuexPersistence({
  storage: sessionStorage,
});

export default new Vuex.Store({
  state: {
    // auth
    sessionToken: "",
    user: {} as IUser,
    modules: new Array<IModule>(),
    permissions: new Array<IPermission>(),
    regions: new Array<IRegion>(),
    region: {} as IPerson,
    section: {} as ISection,
    loggedIn: false,
    globalLoading: false,

    // layout
    navDrawerVisible: false,
    pageTitle: "Worganize",
    pageIcon: "mdi-dashboard",
    theme: "Worganize",
    dark: false,
  },

  mutations: {
    setNavDrawer(state, visible: boolean) {
      state.navDrawerVisible = visible;
    },

    setPageData(state, payload: { title: string; icon: string }) {
      state.pageTitle = payload.title;
      state.pageIcon = payload.icon;
    },

    login(state, payload: ILogin) {
      state.loggedIn = true;
      state.sessionToken = payload.sessionToken;
      state.user = payload.user;
      state.modules = payload.modules;
      state.permissions = payload.permissions;
      state.regions = payload.regions;
      state.region = payload.region;
      state.section = payload.section;
    },

    logout(state) {
      state.loggedIn = false;
      state.sessionToken = "";
      state.user = {} as IUser;
      state.modules = [];
      state.permissions = [];
      state.regions = [];
      state.region = {} as IPerson;
      state.section = {} as ISection;
    },

    updateUser(state, user: IUser) {
      state.user = { ...user };
    },

    setSessionToken(state, sessionToken: string) {
      state.sessionToken = sessionToken;
    },

    setTheme(state, name: string) {
      state.theme = name;
    },

    setDark(state, dark: boolean) {
      state.dark = dark;
    },

    globalLoading(state, value: boolean) {
      state.globalLoading = value;
    },
  },

  actions: {},

  modules: {},

  plugins: [vuexPersistence.plugin],
});
