
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "ACard",

  components: {},

  props: {
    title: {
      type: String as PropType<string>,
    },

    icon: {
      type: String as PropType<string>,
    },

    flat: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    elevation: {
      type: String as PropType<string>,
      default: "0",
    },

    noGutters: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    showClose: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    width: {
      type: String as PropType<string>,
      default: undefined,
    },
  },

  data() {
    return {};
  },

  computed: {
    cShowClose(): boolean {
      return this.showClose && !this.$route.meta?.hideClose;
    },
  },

  watch: {},
});
