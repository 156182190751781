
import { Rq } from "@/lib/amdt";
import Api from "@/lib/api";
import VForm from "@/lib/types/v-form";
import VTextField from "@/lib/types/v-text-field";
import {
  equalsLength,
  maxLength,
  minLength,
  passwordMatch,
  required,
} from "@/lib/validations";
import WorgError from "@/lib/worg-error";
import Vue from "vue";
import ABtn from "../elements/a-btn.vue";
import vAlertMessage from "./v-alert-message.vue";
import ATextField from "../elements/a-text-field.vue";
import ACard from "../elements/a-card.vue";
import ATextFieldPassword from "../elements/a-text-field-password.vue";

export default Vue.extend({
  name: "PasswordRecovery",

  components: { vAlertMessage, ABtn, ATextField, ACard, ATextFieldPassword },

  data() {
    return {
      valid: false,
      loading: false,
      ret_id: 1,
      ret_msg: "",

      usingCode: false,
      username: "",
      code: "",
      password: "",
      passwordConfirmation: "",
    };
  },

  computed: {
    form(): VForm {
      return this.$refs.form as VForm;
    },

    usernameInput(): VTextField {
      return this.$refs.username as VTextField;
    },

    codeInput(): VTextField {
      return this.$refs.code as VTextField;
    },

    cUsernameRules(): ((value: string) => string | boolean)[] {
      return this.usingCode ? [] : [required, maxLength(25)];
    },

    cCodeRules(): ((value: string) => string | boolean)[] {
      return this.usingCode ? [required, equalsLength(6)] : [];
    },

    cPasswordRules(): ((value: string) => string | boolean)[] {
      return this.usingCode ? [required, minLength(8), maxLength(40)] : [];
    },

    cPasswordConfirmationRules(): ((value: string) => string | boolean)[] {
      return this.password
        ? [required, passwordMatch(this.password, true)]
        : [passwordMatch(this.password)];
    },
  },

  mounted() {
    this.usernameInput.focus();
  },

  methods: {
    evOnSubmit() {
      if (!this.usingCode) this.evOnSubmitRequest().then();
      else this.evOnSubmitReset().then();
    },

    async evOnSubmitRequest() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("user.request-password-reset", {
          username: this.username,
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;

          if (WorgError.getErrorByCode(this.ret_id).name === "ErrNoRows")
            this.ret_msg =
              "Usuário não encontrado ou sem e-mail cadastrado. Contate o suporte para alterar sua senha";
          else this.ret_msg = rsp.ret_msg;

          return;
        }

        this.ret_msg = "Um código de verificação foi enviado ao seu e-mail";
        this.usingCode = true;
        setTimeout(() => {
          this.codeInput.focus();
        }, 100);

        this.code = "";
        this.password = "";
        this.passwordConfirmation = "";

        this.form.resetValidation();
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async evOnSubmitReset() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("user.reset-password", {
          codigo: this.code,
          senha: this.password,
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;

          if (WorgError.getErrorByCode(this.ret_id).name === "ErrNoRows")
            this.ret_msg = "Código inválido";
          else this.ret_msg = rsp.ret_msg;

          return;
        }

        this.code = "";
        this.password = "";
        this.passwordConfirmation = "";
        this.form.resetValidation();

        this.ret_msg = "Sua senha foi alterada com sucesso";
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
