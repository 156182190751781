
import Vue from "vue";
import { setTheme } from "@/lib/theming";
import aBtn from "../elements/a-btn.vue";
import MoreMenu from "./app-bar/more-menu.vue";

export default Vue.extend({
  name: "AppBar",

  components: { aBtn, MoreMenu },

  computed: {
    visible: {
      get(): boolean {
        return this.$store.state.navDrawerVisible;
      },
      set(visible: boolean) {
        this.$store.commit("setNavDrawer", visible);
      },
    },

    globalLoading(): boolean {
      return this.$store.state.globalLoading;
    },

    loggedIn(): boolean {
      return this.$store.state.loggedIn;
    },

    isMobile(): boolean {
      return this.$vuetify.breakpoint.mobile;
    },

    cDark(): boolean {
      return !this.$vuetify.theme.dark;
    },
  },

  methods: {
    setTheme,
  },
});
