export default {
  Unknown: { code: 0, message: "Erro desconhecido" },

  // auth
  AuthNotSignedIn: { code: -10, message: "Faça login para continuar" },
  AuthWrongCredentials: {
    code: -11,
    message: "Usuário e/ou senha incorretos",
  },
  AuthSessionExpired: { code: -12, message: "Sessão expirada" },
  AuthSessionSaveError: {
    code: -13,
    message: "Não foi possível guardar a sessão",
  },
  AuthNotAuthorized: { code: -14, message: "Não autorizado" },
  AuthUsuarioEmpresaDeactivated: {
    code: -15,
    message: "Usuário-empresa desativado",
  },
  AuthUserDeactivated: { code: -16, message: "Usuário desativado" },
  AuthProfileDeactivated: { code: -17, message: "Perfil desativado" },
  AuthSectionDeactivated: { code: -18, message: "Setor desativado" },
  AuthCompanyDeactivated: { code: -19, message: "Empresa desativada" },
  AuthNoCompaniesLinked: {
    code: -190,
    message: "Nenhuma empresa vinculada com o usuário",
  },
  AuthTokenExpired: { code: -191, message: "Código expirado" },
  AuthInvalidSection: { code: -192, message: "Setor inválido" },
  AuthPasswordExpired: { code: -193, message: "Senha expirada" },

  // banco
  ErrUnkwnown: { code: -901, message: "Erro Nº. -901, contate o suporte" },
  ErrConnectError: {
    code: -902,
    message: "Erro Nº. -902, contate o suporte",
  },
  ErrQueryError: { code: -903, message: "Erro Nº -903, contate o suporte" },
  ErrConstraintError: {
    code: -904,
    message: "Um ou mais campos já existem no sistema",
  },
  ErrNoRows: { code: -905, message: "Nenhum cadastro encontrado" },
  ErrDupEntry: {
    code: -906,
    message: "Já existe um registro com esse valor",
  },

  // conversões

  // validações
  VldError: { code: -40, message: "Verifique os campos e tente novamente" },

  // parâmetros
};
