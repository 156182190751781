
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "ABtn",

  props: {
    block: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    color: {
      type: String as PropType<string>,
      default: "button",
    },

    depressed: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    icon: {
      type: String as PropType<string>,
      default: undefined,
    },

    link: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    outlined: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    small: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    submit: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    text: {
      type: String as PropType<string>,
      default: "",
    },

    to: {
      type: Object as PropType<Record<string, unknown>>,
    },
  },

  computed: {
    type(): string {
      return this.submit ? "submit" : "button";
    },

    btnIconAttr(): boolean {
      return !this.text && Boolean(this.icon);
    },

    cClass(): string {
      const classes = ["white--text", "text-capitalize"];
      // if (!this.btnIconAttr) classes.push("px-8");

      return classes.join(" ");
    },
  },
});
