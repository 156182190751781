export function objectCompare(
  obj1: Record<string, unknown>,
  obj2: Record<string, unknown>
): boolean {
  try {
    const keys = Object.keys(obj1);

    if (keys.length !== Object.keys(obj2).length) {
      return false;
    }

    for (const key of keys) {
      const target = obj1[key];

      if (target && typeof target === "object") {
        if (
          !objectCompare(
            target as Record<string, unknown>,
            obj2[key] as Record<string, unknown>
          )
        )
          return false;

        continue;
      }

      if (target !== obj2[key]) return false;
    }

    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export function parseBRL(value: string): number {
  if (!value) return 0;
  return Number(value.replaceAll(/[^\d,]/g, "").replace(",", "."));
}

export function numberPadding(value: string | number): string {
  value = Number(value);

  if (value < 10) return `00${value}`;
  else if (value < 100) return `0${value}`;
  else return value.toString();
}
