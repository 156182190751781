
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "ASelect",

  props: {
    autofocus: {
      type: Boolean as PropType<boolean>,
      default: undefined,
    },

    backgroundColor: {
      type: String as PropType<string>,
      default: "inputbg",
    },

    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    items: {
      type: Array as PropType<Array<Record<string, unknown>>>,
      required: true,
    },

    itemText: {
      type: String as PropType<string>,
      default: "",
    },

    itemValue: {
      type: String as PropType<string>,
      default: "",
    },

    label: {
      type: String as PropType<string | undefined>,
      required: true,
    },

    loading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    multiple: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    readonly: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    rules: {
      type: Array as PropType<Array<boolean | string>>,
      default: () => [],
    },

    value: {
      type: [String, Number, Array] as PropType<
        string | number | Array<string | number>
      >,
      default: "",
    },
  },

  computed: {
    cItemText(): string {
      if (this.itemText || this.items.length === 0) return this.itemText;

      const item = this.items[0] as Record<string, unknown>;
      const keys = Object.keys(item);

      const options = [
        "nome",
        "descricao",
        "name",
        "description",
        "text",
        "id",
      ];
      return options.find((op) => keys.includes(op)) ?? keys[0];
    },

    cItemValue(): string {
      if (this.itemValue || this.items.length === 0) return this.itemValue;

      const item = this.items[0] as Record<string, unknown>;
      const keys = Object.keys(item);

      const options = ["id", "value"];
      return options.find((op) => keys.includes(op)) ?? keys[0];
    },
  },
});
